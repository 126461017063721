import loadable from "@loadable/component"
import _ from "lodash"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import FeaturedPropertiesCard from "../FeaturedPropertiesCard/FeaturedPropertiesCard"
import { sliderSettings } from "../SliderSettings/SliderSettings"
import "./assets/styles/_index.scss"

const Slider = loadable(() => import("react-slick"))
const KEYS = {
  sale: "for-sale",
  rent: "for-rent",
  offplan: "off-plan",
  global: "global-properties",
}

const options = [
  { label: "For Sale", value: "for-sale" },
  { label: "For Rent", value: "for-rent" },
  { label: "Off Plan", value: "off-plan" },
]
const AreaGuideProperties = (props) => {
  const [key, setKey] = useState(KEYS.sale)

  const [saleLists, setSaleLists] = useState(props?.sales)
  const [rentLists, setRentLists] = useState(props?.lettings)
  const [offplanLists, setoffplanLists] = useState(props?.offplan)
  // console.log(props?.offplan)
  const [selectedOption, setSelectedOption] = useState("for-sale")

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const SliderTab = ({ items, offplan }) => {
    if (_.isEmpty(items)) return null

    return (
      <Slider className="featured-properties-slider" {...sliderSettings}>
        {offplan
          ? items?.map(({ node }, i) => {
              const details_path = "/project-for-sale"

              let processedImages = JSON.stringify({})
              if (node?.imagetransforms?.images_Transforms) {
                processedImages = node?.imagetransforms?.images_Transforms
              }

              const image_all = offplan
                ? node.images
                : node.images?.strapi_json_value

              return (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={i * 100}
                  animateOnce
                  offset={10}
                >
                  <FeaturedPropertiesCard
                    details_path={details_path}
                    {...node}
                    img={image_all}
                    processedImages={processedImages}
                    status="Under Offer"
                    bedroom={node.bedroom}
                    bathroom={node.bathroom}
                    floorarea_min={node.floorarea_min}
                    floorarea_type={node.floorarea_type}
                    address={node.display_address}
                    priceTag={node.price_qualifier}
                    price={node?.price?.toLocaleString()}
                    title={node.title}
                    slug={node?.slug}
                    crmID={node.crm_id}
                    search_type={node.search_type}
                    department={node.department}
                    id={node.strapi_id}
                  />
                </ScrollAnimation>
              )
            })
          : items?.map((item, i) => {
              let details_path = "/property-for-sale"
              if (item.search_type == "lettings") {
                details_path = "/property-for-rent"
              }

              let processedImages = JSON.stringify({})
              if (item?.imagetransforms?.images_Transforms) {
                processedImages = item?.imagetransforms?.images_Transforms
              }

              const image_all = item.images?.strapi_json_value

              return (
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={i * 100}
                  animateOnce
                  offset={10}
                >
                  <FeaturedPropertiesCard
                    details_path={details_path}
                    {...item}
                    img={image_all}
                    processedImages={processedImages}
                    status="Under Offer"
                    bedroom={item.bedroom}
                    bathroom={item.bathroom}
                    floorarea_min={item.floorarea_min}
                    floorarea_type={item.floorarea_type}
                    address={item.display_address}
                    priceTag={item.price_qualifier}
                    price={item?.price?.toLocaleString()}
                    title={item.title}
                    slug={item?.slug}
                    crmID={item.crm_id}
                    search_type={item.search_type}
                    department={item.department}
                    id={item.strapi_id}
                  />
                </ScrollAnimation>
              )
            })}
      </Slider>
    )
  }

  const { isMobile } = useDeviceMedia()

  return saleLists?.length > 0 ||
    rentLists?.length > 0 ||
    offplanLists?.length > 0 ? (
    <section className="featured-properties-wrapper section-p">
      <Container>
        <Row>
          <Col>
            <div className="relative">
              <div className="featured-heading-wrapper">
                <h2>Find your next property in {props?.title}</h2>
              </div>
              {isMobile ? (
                <>
                  <div className="select_feature">
                    <Form.Select
                      onChange={handleSelectChange}
                      value={selectedOption}
                      aria-label="Default select example"
                    >
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  {selectedOption === "for-sale" && saleLists?.length > 0 && (
                    <div className="featured-properties-tab-section">
                      <SliderTab items={saleLists} />
                    </div>
                  )}
                  {selectedOption === "for-rent" && rentLists?.length > 0 && (
                    <SliderTab items={rentLists} />
                  )}
                  {selectedOption === "off-plan" &&
                    offplanLists?.length > 0 && (
                      <SliderTab offplan items={offplanLists} />
                    )}
                </>
              ) : (
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="featured-properties-tab-section"
                >
                  {saleLists?.length > 0 && (
                    <Tab eventKey={KEYS.sale} title="For Sale">
                      <SliderTab items={saleLists} />
                    </Tab>
                  )}
                  {rentLists?.length > 0 && (
                    <Tab eventKey={KEYS.rent} title="For Rent">
                      <SliderTab items={rentLists} />
                    </Tab>
                  )}
                  {offplanLists?.length > 0 && (
                    <Tab eventKey={KEYS.offplan} title="Off Plan">
                      <SliderTab offplan items={offplanLists} />
                    </Tab>
                  )}
                </Tabs>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* {propItems.length > 0 && (
        <Container>
          <Row className="d-flex align-items-center featured-heading-wrapper">
            <Col md={3} />
            <Col md={6} className="text-center">
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={200}
                animateOnce
                offset={10}
              >
                <h2>{props?.title}</h2>
              </ScrollAnimation>
            </Col>
            {props.tag === "property-details" ||
            props.tag === "landing-page" ? (
              ""
            ) : (
              <Col md={3} className="d-md-flex justify-content-end text-center">
                <ScrollAnimation
                  animateIn="animate__slideInUp"
                  delay={200}
                  animateOnce
                  offset={10}
                >
                  <Link
                    to={`/${PageLinks.results_sales}/`}
                    className="button button-primary"
                  >
                    View More
                  </Link>
                </ScrollAnimation>
              </Col>
            )}
          </Row>

          <Row>
            <Col>
              <Slider
                className="featured-properties-slider"
                {...sliderSettings}
              >
                {propItems?.map((item, i) => {
                  let details_path = "/property-for-sale"
                  if (item.search_type == "lettings") {
                    details_path = "/property-for-rent"
                  }

                  let processedImages = JSON.stringify({})
                  if (item?.imagetransforms?.images_Transforms) {
                    processedImages = item?.imagetransforms?.images_Transforms
                  }

                  const image_all = JSON.parse(item.images.replace('"', '"'))

                  return (
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={i * 100}
                      animateOnce
                      offset={10}
                    >
                      <FeaturedPropertiesCard
                        details_path={details_path}
                        {...item}
                        img={image_all}
                        processedImages={processedImages}
                        status="Under Offer"
                        address={item.display_address}
                        priceTag="Guide Price"
                        price={item?.price?.toLocaleString()}
                        title={item.title}
                      />
                    </ScrollAnimation>
                  )
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      )} */}
    </section>
  ) : null
}

export default AreaGuideProperties
