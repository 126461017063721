import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import ReadMore from "../ReadMore/ReadMore"
import "./assets/styles/_index.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const TileBlock = (props) => {
  const imagename = props?.event
    ? "event.tile_section_image.tile_image"
    : props?.areaGuide ? "community.tile_section_image.tile_image"
    : "page.tile_section_image.tile_image"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.tile_section_image_Transforms) {
    processedImages = props?.imagetransforms?.tile_section_image_Transforms
  }
  
  return (
    <section
      className={`tile-block-wrapper section-p ${
        props?.background_color_transparent ? "transparent" : ""
      }    ${props?.event ? "event" : ""}`}
    >
      <Container>
        <Row className="d-flex align-items-center">
          <Col xl={6}>
            {props?.image && (
              <div className="img-wrapper tile-img-wrapper">
                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={100}
                  animateOnce
                  offset={50}
                >
                  {/* {console.log(props?.image)}
                  {console.log(processedImages)}
                  {console.log(imagename)}
                  {console.log(props?.id)} */}
                  <ImageModule
                    ImageSrc={props?.image}
                    altText={props?.image?.alternativeText}
                    imagetransforms={processedImages}
                    renderer="srcSet"
                    imagename={imagename}
                    strapi_id={props?.id}
                    classNames="img-fluid"
                  />
                </ScrollAnimation>
              </div>
            )}
          </Col>
          <Col xl={1} />
          <Col xl={5}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.primary_heading && <div className="h6">{props?.primary_heading}</div>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.secondary_heading && <h2>{props?.secondary_heading}</h2>}
            </ScrollAnimation>
            {props?.description && (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={100}
                animateOnce
                offset={50}
              >
                <ReadMore
                  content={props?.description?.data?.description}
                  height={154}
                  className="property-desc-text"
                  parentClassName=".property-desc-wrapper"
                />
              </ScrollAnimation>
            )}
            {props?.cta_link && (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={300}
                animateOnce
                offset={50}
              >
                <CTALink
                  class="button button-secondary-outline"
                  link={props?.cta_link}
                  title={props?.cta_title}
                  target_window={props.cta_link?.target_window}
                />
              </ScrollAnimation>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TileBlock
