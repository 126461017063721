import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import RCFormFields from "../../static/forms/request_a_callback_form.json"
import TeamFormFields from "../../static/forms/team_form.json"
import { getWhatsAppURL } from "../common/site/config"
import { PageLinks } from "../common/site/page-static-links"
import AreaGuideBanner from "../components/AreaGuideBanner/AreaGuideBanner"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import CommunityModule from "../components/CommunityModule/CommunityModule"
import CommunityStatistics from "../components/CommunityStatistics/CommunityStatistics"
import AreaGuideProperties from "../components/FeaturedProperties/AreaGuideProperties"
import ImageCard from "../components/ImageCard/ImageCard"
import ShowcaseComponent from "../components/ShowcaseComponent/ShowcaseComponent"
import TileBlock from "../components/TileBlock/TileBlock"
import TileBlockRight from "../components/TileBlockRight/TileBlockRight"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"

const GoogleMap = loadable(() => import("../components/maps/google/single"))
const DefaultForm = loadable(() =>
  import("../components/forms/default-form-layout")
)
const AreaGuideLandingTemplate = ({ data }) => {
  const PageData = data?.strapiCommunity
  const communityList = data?.allStrapiCommunity?.edges
  const sales = data?.sales?.nodes
  const lettings = data?.lettings?.nodes
  const offplan = data?.offplan?.edges
  const globalContent =
    data?.allStrapiGlobalContent?.edges?.length > 0 &&
    data?.allStrapiGlobalContent?.edges[0]
  // console.log(data)
  const { isMobile } = useDeviceMedia()

  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  const [showRC, setShowRC] = useState(false)
  const handleModalRC = () => {
    setShowRC(true)
  }

  const closeModalRC = () => {
    setShowRC(false)
  }
  return (
    <Layout dark isFooterCta>
      <div className="layout-padding-top">
        <BreadcrumbModule
          subparentlabel={PageLinks?.guidelabel}
          subparentlink={PageLinks?.guide}
          title={PageData?.banner?.banner_title}
          parentlabel={PageLinks?.insightslabel}
          parentlink={PageLinks?.insights}
        />
        <AreaGuideBanner data={PageData} />
        {PageData?.add_module?.length > 0 &&
          PageData?.add_module?.map((module, index) => {
            return (
              <div key={[index]} className="landing-page area-guide-community">
                {module.strapi_component ===
                  "page-modules.stat-with-content" && (
                  <CommunityStatistics
                    tag="landing"
                    {...module}
                    isGuide
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                    choose_team={PageData?.choose_team}
                    globalContent={globalContent}
                  />
                )}

                {module.strapi_component === "page-modules.image-and-content" &&
                  module.image_alignment === "left" && (
                    <TileBlock
                      {...module}
                      id={PageData.strapi_id}
                      imagetransforms={PageData.imagetransforms}
                      areaGuide
                    />
                  )}

                {module.strapi_component === "page-modules.image-and-content" &&
                  module.image_alignment === "right" && (
                    <TileBlockRight
                      {...module}
                      id={PageData.strapi_id}
                      imagetransforms={PageData.imagetransforms}
                      areaGuide
                      data={PageData}
                    />
                  )}

                {/* {module?.strapi_component === "page-modules.accordion" && (
                  <Accordian {...module} />
                )} */}
                {index === PageData?.add_module?.length - 1 && (
                  <>
                    {PageData?.longitude && PageData?.latitude && (
                      <GoogleMap
                        lat={parseFloat(PageData?.latitude)}
                        lng={parseFloat(PageData?.longitude)}
                      />
                    )}
                    <AreaGuideProperties
                      title={PageData.title}
                      sales={sales}
                      lettings={lettings}
                      offplan={offplan}
                    />
                    <CommunityModule
                      title="Other Communites"
                      add_items={communityList}
                    />
                  </>
                )}
                {module.strapi_component === "components.market-banner" && (
                  <ShowcaseComponent
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                    data={module}
                  />
                )}
                {/* {module?.strapi_component ===
                  "page-modules.image-with-page" && (
                  <ImageCard
                    {...module}
                    slide_count="4"
                    isGuide
                    id={PageData.strapi_id}
                    imagetransforms={PageData.imagetransforms}
                  />
                )} */}
              </div>
            )
          })}
      </div>

      {PageData?.choose_team?.name && (
        <div className="areaguide-teamcard mobile">
          <div className="ctas-section">
            <a
              className="button button-primary-color"
              // href={`tel:${PageData?.choose_team?.phone}`}
              href="javascript:void(0)"
              onClick={() => handleModalRC()}
            >
              <i className="icon black-call-icon" />
              <span>Request a callback</span>
            </a>
            <div className="more-ctas">
              <a
                className="button button-secondary-outline"
                href="javascript:void(0)"
                onClick={() => handleModal()}
              >
                <i className="icon icon-email-black" />
                {!isMobile && <span>Email</span>}
              </a>
              <a
                target="_blank"
                className="button button-secondary-outline"
                href={`${getWhatsAppURL(PageData?.choose_team?.whatsapp)}`}
                rel="noreferrer"
              >
                <i className="icon whatsapp-green-icon" />
                {!isMobile && <span>Whatsapp</span>}
              </a>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Email to {PageData?.choose_team?.name}</h2>
            <DefaultForm
              fields={TeamFormFields}
              formtagclassname="team-form"
              to_email_id={PageData?.choose_team?.email}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRC}
        onHide={() => setShowRC(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModalRC()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Request a call back</h2>
            <DefaultForm
              fields={RCFormFields}
              formtagclassname="team-form"
              // to_email_id={}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiCommunity
  const metaDescription = `Know more about ${PageData?.title} here. Contact one of our estate agents for assistance in finding the right property for you in ${PageData?.title}`

  return (
    <Seo
      title={`${PageData?.title} Area Guide`}
      description={metaDescription}
    />
  )
}

export default AreaGuideLandingTemplate

export const query = graphql`
  query ($page_id: Int, $title: String) {
    strapiCommunity(strapi_id: { eq: $page_id }) {
      ...CommunityFragment
      title
      slug
      choose_team {
        name
        designation
        whatsapp
        email
        phone
        imagetransforms {
          image_Transforms
        }
        slug
        brn_number
        strapi_id
        image {
          url
        }
      }
      add_module {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          strapi_component
          heading
          description {
            data {
              description
            }
          }
          add {
            id
            title
            content {
              data {
                content
              }
            }
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_MARKET_BANNER {
          ...MarketBannerFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
          id
          ...ImageAndContentFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STAT_WITH_CONTENT {
          id
          title
          strapi_component
          stat_with_data {
            stats_description
            stats_title
          }
          content {
            data {
              content
            }
          }
          cta_btn {
            link_type
            cta_label
            cta_link {
              slug
            }
          }
        }
      }
    }

    sales: allStrapiProperty(
      filter: {
        area: { eq: $title }
        search_type: { eq: "sales" }
        department: { eq: "residential" }
      }
      limit: 6
    ) {
      nodes {
        ...propertyFragment
      }
    }

    lettings: allStrapiProperty(
      filter: {
        area: { eq: $title }
        search_type: { eq: "lettings" }
        department: { eq: "residential" }
      }
      limit: 6
    ) {
      nodes {
        ...propertyFragment
      }
    }

    offplan: allProjects(
      filter: { area: { eq: $title }, publish: { eq: true } }
      limit: 6
    ) {
      edges {
        node {
          images {
            url
          }
          id
          imagetransforms {
            images_Transforms
          }
          slug
          title
          display_address
          strapi_id
          price
          price_qualifier
          bedroom
          status
          department
          crm_id
        }
      }
    }

    allStrapiCommunity(filter: { title: { ne: $title } }, limit: 9) {
      edges {
        node {
          tail_image {
            url
          }
          imagetransforms {
            tile_image_Transforms
            popular_section_Transforms
          }
          title
          strapi_id
          slug
          description {
            data {
              description
            }
          }
        }
      }
    }
    allStrapiGlobalContent {
      edges {
        node {
          community_team_content {
            data {
              community_team_content
            }
          }
          community_team_title
        }
      }
    }
  }
`
