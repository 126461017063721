import { Link } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { PageLinks } from "../../common/site/page-static-links"
import CommunityCustomSlider from "../CustomSlider/CommunityCustomSlider"
import "./CommunityModule.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const CommunityModule = (props) => {
  return (
    <section className="popular-community-bk">
      <Container>
        <Row>
          <Col xl={9} lg={8} md={8}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.title && <h2>{props?.title}</h2>}
            </ScrollAnimation>
          </Col>
          <Col xl={3} lg={4} md={4}>
            <Link
              className="button button-secondary-outline"
              to={`/${PageLinks.community}`}
            >
              Go back to area guides
            </Link>
          </Col>
        </Row>
        {props?.add_items?.length > 0 ? (
          <CommunityCustomSlider
            slidecount={1}
            className="community-card-wrapper"
          >
            {props?.add_items?.map((item, i) => {
              const imagename = "community.popular_section.tail_image"

              let processedImages = JSON.stringify({})
              if (
                item?.node?.imagetransforms?.popular_section_Transforms
              ) {
                processedImages =
                  item?.node?.imagetransforms?.popular_section_Transforms
              }
              return (
                <div className="card">
                  <div className="item-card">
                    <div className="image-card">
                      <Link to={`/${PageLinks.community}/${item?.node?.slug}`}>
                        <ImageModule
                          ImageSrc={item?.node?.tail_image}
                          altText={item?.node?.tail_image?.alternativeText}
                          imagetransforms={processedImages}
                          renderer="srcSet"
                          imagename={imagename}
                          strapi_id={item?.node?.strapi_id}
                          classNames="img-card"
                        />
                      </Link>
                    </div>
                    <div>
                      <h6>
                        {" "}
                        <Link
                          to={`/${PageLinks.community}/${item?.node?.slug}`}
                        >
                          {item.node?.title}
                        </Link>
                      </h6>
                      <ContentModule
                        Content={item.node?.description?.data?.description}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </CommunityCustomSlider>
        ) : null}
      </Container>
    </section>
  )
}

export default CommunityModule
