import loadable from "@loadable/component"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Modal, Row } from "react-bootstrap"
import BrochureForm from "../../../static/forms/download_guide_form.json"
import FormLayout from "../FormLayout/FormLayout"
import ReadMore from "../ReadMore/ReadMore"
import CloseIcon from "../icons/CloseIcon"
import "./assets/styles/_index.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const TileBlockRight = (props) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const imagename = props?.areaGuide
    ? "community.tile_section_image.tile_image"
    : "page.tile_section_image.tile_image"

  let processedImages = JSON.stringify({})
  if (props?.imagetransforms?.tile_section_image_Transforms) {
    processedImages = props?.imagetransforms?.tile_section_image_Transforms
  }
  // console.log(props)
  return (
    <section
      className={`tile-block-right-wrapper section-p ${
        props?.background_color_transparent ? "transparent" : ""
      } ${props?.event ? "event" : ""}`}
    >
      <Container>
        <Row className="d-flex align-items-center">
          <Col xl={5} className="order-xl-1 order-2">
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.primary_heading && <div className="h6">{props?.primary_heading}</div>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.secondary_heading && <h2>{props?.secondary_heading}</h2>}
            </ScrollAnimation>
            {props?.description && (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={100}
                animateOnce
                offset={50}
              >
                <ReadMore
                  content={props?.description?.data?.description}
                  height={154}
                  className="property-desc-text"
                  parentClassName=".property-desc-wrapper"
                />
              </ScrollAnimation>
            )}
            {props?.areaGuide ? (
              <>
                {props?.cta_link ||
                  (props?.custom_link && (
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={300}
                      animateOnce
                      offset={50}
                    >
                      {props?.cta_title === "Download the guide" ? (
                        <button
                          className="button button-primary"
                          onClick={handleShow}
                        >
                          <span>{props?.cta_title}</span>
                        </button>
                      ) : (props?.cta_title && props?.cta_link) || (props?.cta_title && props?.custom_link) ? (
                        <CTALink
                          class="button button-primary"
                          link={
                            props?.cta_link
                              ? props?.cta_link
                              : { external_link: props?.custom_link }
                          }
                          title={props?.cta_title}
                          target_window={props?.custom_link ? "new_window" : ""}
                        />
                      ) : null}
                    </ScrollAnimation>
                  ))}
              </>
            ) :(props?.cta_title && props?.cta_link) || (props?.cta_title && props?.custom_link) ?
              (<CTALink
                class="button button-secondary-outline"
                link={
                  props?.cta_link
                    ? props?.cta_link
                    : { external_link: props?.custom_link }
                }
                title={props?.cta_title}
                target_window={props.cta_link?.target_window}
              />
            ): null}
          </Col>
          <Col xl={1} className="order-xl-2" />
          <Col xl={6} className="order-xl-3 order-1">
            {props?.image && (
              <div className="img-wrapper tile-right-img-wrapper">
                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  delay={100}
                  animateOnce
                  offset={50}
                >
                  <ImageModule
                    ImageSrc={props?.image}
                    altText={props?.image?.alternativeText}
                    imagetransforms={processedImages}
                    renderer="srcSet"
                    imagename={imagename}
                    strapi_id={props?.id}
                    classNames="img-fluid"
                  />
                </ScrollAnimation>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="team-contact-modal"
      >
        <Modal.Body className="team-contact-modal-body">
          <div className="team-contact-modal-header">
            <div className="content-section">
              <h3 className="title">Download Guide</h3>
              {/* <p className="description">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit.
                    </p> */}
            </div>
            <CloseIcon onClick={handleClose} />
          </div>
          <FormLayout>
            <DefaultForm
              fields={BrochureForm}
              classname="enquiry-form-wrapper"
              // to_email_id={to_email_id}
              // emailData={emailData}
              prop_url={props?.data?.banner?.guide_link?.url}
              job={props?.data?.banner?.banner_title}
            />
          </FormLayout>
          {/* <BrochureForm offplan={offplan} /> */}
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default TileBlockRight
