import loadable from "@loadable/component"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Modal, Row } from "react-bootstrap"
import BrochureForm from "../../../static/forms/download_guide_form.json"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import BannerSearch from "../BannerSearch/BannerSearch"
import FormLayout from "../FormLayout/FormLayout"
import CloseIcon from "../icons/CloseIcon"
import "./AreaGuideBanner.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const AreaGuideBanner = ({ data }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const { isMobile } = useDeviceMedia()
  let processedImages = JSON.stringify({})
  if (data?.imagetransforms) {
    processedImages = data?.imagetransforms?.image_Transforms
  }
  // console.log(processedImages)
  const imagename = "community.image.banner_image"
  return (
    <section className="banner d-xl-flex align-items-center landing-banner">
      <ImageModule
        ImageSrc={data?.banner?.image}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={data?.strapi_id}
        classNames="img-fluid banner-img"
      />
      <div className="overlay-bg" />

      <Container className="banner-search-container">
        <Row>
          <Col xl={6}>
            {data?.banner?.banner_title && (
              <ScrollAnimation
                delay={100}
                animateIn="animate__slideInUp"
                animateOnce
              >
                <h1>{data?.banner?.banner_title}</h1>
              </ScrollAnimation>
            )}
            {data?.banner?.banner_content && (
              <ScrollAnimation
                animateIn="animate__slideInUp"
                delay={300}
                animateOnce
                offset={10}
              >
                <ContentModule
                  Content={data?.banner?.banner_content?.data?.banner_content}
                />
              </ScrollAnimation>
            )}
            <BannerSearch areaVal={data?.slug} />
            <ul className="list-inline button-list">
              {data?.banner?.ctas_intro_text && (
                <p className="banner_intro_text">
                  {data?.banner?.ctas_intro_text}
                </p>
              )}
              {data?.banner?.cta_1_title &&
                (data?.banner?.cta_1_link || data?.banner?.cta1_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={400}
                      animateOnce
                      offset={10}
                    >
                      <CTALink
                        class="button button-secondary-outline"
                        link={
                          data?.banner?.cta_1_link
                            ? data?.banner?.cta_1_link
                            : { external_link: data?.banner?.cta1_custom }
                        }
                        title={data?.banner?.cta_1_title}
                        target_window={data?.banner?.cta_1_link?.target_window}
                      />
                    </ScrollAnimation>
                  </li>
                )}
              {data?.banner?.cta_2_title &&
                (data?.banner?.cta_2_link || data?.banner?.cta2_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={500}
                      animateOnce
                      offset={10}
                    >
                      <CTALink
                        class="button button-secondary-outline"
                        link={
                          data?.banner?.cta_2_link
                            ? data?.banner?.cta_2_link
                            : { external_link: data?.banner?.cta2_custom }
                        }
                        title={data?.banner?.cta_2_title}
                        target_window={data?.banner?.cta_2_link?.target_window}
                      />
                    </ScrollAnimation>
                  </li>
                )}
              {data?.banner?.cta_3_title &&
                (data?.banner?.cta_3_link || data?.banner?.cta3_custom) && (
                  <li className="list-inline-item">
                    <ScrollAnimation
                      animateIn="animate__slideInUp"
                      delay={400}
                      animateOnce
                      offset={10}
                    >
                      <CTALink
                        class="button button-secondary-outline"
                        link={
                          data?.banner?.cta_3_link
                            ? data?.banner?.cta_3_link
                            : { external_link: data?.banner?.cta3_custom }
                        }
                        title={data?.banner?.cta_3_title}
                        target_window={data?.banner?.cta_3_link.link_type}
                      />
                    </ScrollAnimation>
                  </li>
                )}
            </ul>
            <Modal
              show={show}
              onHide={handleClose}
              centered
              className="team-contact-modal"
            >
              <Modal.Body className="team-contact-modal-body">
                <div className="team-contact-modal-header">
                  <div className="content-section">
                    <h3 className="title">
                      {isMobile ? "Download Guide" : data?.banner?.cta_1_title}
                    </h3>
                    {/* <p className="description">
                      Lorem ipsum dolor sit amet consectetur adipiscing elit.
                    </p> */}
                  </div>
                  <CloseIcon onClick={handleClose} />
                </div>
                <FormLayout>
                  <DefaultForm
                    fields={BrochureForm}
                    classname="enquiry-form-wrapper"
                    // to_email_id={to_email_id}
                    // emailData={emailData}
                    prop_url={data?.banner?.guide_link?.url}
                    job={data?.banner?.banner_title}
                  />
                </FormLayout>
                {/* <BrochureForm offplan={offplan} /> */}
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AreaGuideBanner
