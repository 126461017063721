import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Row } from "react-bootstrap"
import "./assets/styles/_index.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const StatsModule = (props) => {
  const {isTablet} = useDeviceMedia()
  return (
    <>
      {props?.stats && props?.stats?.length > 0 && (
        <div
          className={`stats-wrapper ${
            props.tag === "landing_page" ? "inner-page" : ""
          }`}
        >
          <Row className="d-flex flex-wrapper">
            {props.stats &&
              props.stats.length > 0 &&
              props.stats.map((item, i) => (
                <Col xs={(props.areaGuideDetail&&isTablet)?6:props.areaGuideDetail?3:6}>
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={(i + 1) * 200}
                    offset={100}
                  >
                    <div className="stats-card">
                      <div className="stats-title">{item.stats_title}</div>
                      {item.stats_description && (
                        <div className="stats-text">
                          {item.stats_description}
                        </div>
                      )}
                    </div>
                  </ScrollAnimation>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </>
  )
}

export default StatsModule
