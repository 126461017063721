import loadable from "@loadable/component"
import React, { useState } from "react"
// import { Link } from "gatsby";
import { Container, Modal } from "react-bootstrap"
// import { StaticImage, getImage } from "gatsby-plugin-image";
// import ContentModule from "../../modules/content-render";
import ScrollAnimation from "react-animate-on-scroll"
import StatsModule from "../StatsModule/StatsModule"
// import CTALink from "../../modules/cta_link"
import { Link } from "gatsby"
import RCFormFields from "../../../static/forms/request_a_callback_form.json"
import TeamFormFields from "../../../static/forms/team_form.json"
import { PageLinks } from "../../common/site/page-static-links"
import noImage from "../../images/noImage.png"
import { getWhatsAppURL } from "../../lib/utils"
import ReadMore from "../ReadMore/ReadMore"
import "./CommunityStatistics.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ImageModule")

const DefaultForm = loadable(() => import("../forms/default-form-layout"))

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const {
  CTALink,
} = require("@starberry/gatsby-theme-utils/dist/Modules/CTALinkModule")

const CommunityStatistics = (props) => {
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  const [showRC, setShowRC] = useState(false)
  const handleModalRC = () => {
    setShowRC(true)
  }

  const closeModalRC = () => {
    setShowRC(false)
  }
  return (
    <section className="intro-wrapper">
      <Container>
        <div className="right-left-sections">
          <div className="left-section">
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props.title && <h2>{props.title}</h2>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props.content &&
                (props?.isGuide ? (
                  <ReadMore
                    content={props.content?.data?.content}
                    height={160}
                    className="property-desc-text"
                    parentClassName=".property-desc-wrapper"
                  />
                ) : (
                  <ContentModule Content={props.content?.data?.content} />
                ))}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={300}
              offset={100}
            >
              <StatsModule
                stats={props.stat_with_data}
                tag={props.layout}
                areaGuideDetail
              />
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={300}
              offset={100}
            >
              {props.cta_btn && (
                <CTALink
                  class="button button-secondary-outline"
                  link={props.cta_btn?.cta_link}
                  title={props.cta_btn.cta_label}
                  target_window={props.cta_btn.target_window}
                />
              )}
            </ScrollAnimation>
          </div>

          <div className="right-section">
            {/* {JSON.stringify(props?.choose_team)} */}
            {props?.choose_team?.name && (
              <div className="areaguide-teamcard">
                <div className="cardteam-sections">
                  <div className="contact_team-section">
                    <h4>{props?.globalContent?.node?.community_team_title}</h4>
                    <p>
                    <ContentModule
                    Content={
                      props?.globalContent?.node?.community_team_content?.data
                        ?.community_team_content
                    }
                  />
                    </p>
                    <div className="ctas-section">
                      <a
                        className="button button-primary-color"
                        href="javascript:void(0)"
                        onClick={() => handleModalRC()}
                      >
                        <span>Request a callback</span>
                      </a>
                      <div className="more-ctas">
                        <a
                          className="button button-secondary-outline"
                          href="javascript:void(0)"
                          onClick={() => handleModal()}
                        >
                          <span>Email</span>
                        </a>
                        <a
                          className="button button-secondary-outline"
                          href={`${getWhatsAppURL(
                            props?.choose_team?.whatsapp
                          )}`}
                        >
                          <span>WhatsApp</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="team-member">
                    <div className="image-section">
                      {props?.choose_team?.image ? (
                        // <img
                        //   src={props?.choose_team?.image?.url}
                        //   alt={props?.choose_team?.name}
                        // />
                        <Link to={`/${PageLinks.team}/${props?.choose_team?.slug}/`}>
                        <ImageModule
                          ImageSrc={props?.choose_team?.image}
                          altText="negotiator_image"
                          imagetransforms={
                            props?.choose_team?.imagetransforms
                              ?.image_Transforms
                          }
                          imagename="team.image.propdetails_image"
                          renderer=""
                          strapi_id={props?.choose_team?.strapi_id}
                          classNames="img-fluid"
                        /></Link>
                      ) : (
                        <Link to={`/${PageLinks.team}/${props?.choose_team?.slug}/`}><img src={noImage} alt="No Image" /></Link>
                      )}
                    </div>
                    <div className="team-info">
                      <h4 className="name"><Link to={`/${PageLinks.team}/${props?.choose_team?.slug}/`}>{props?.choose_team?.name}</Link></h4>
                      <p className="designation">
                        {props?.choose_team?.designation}
                      </p>
                      <div className="num">
                      {props?.choose_team?.brn_number && <>
                        <span className="orn-no"> BRN No: {props?.choose_team?.brn_number}</span>
                        <span className="slash">/</span></>}
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleModal()}
                        >
                          Email
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Email to {props?.choose_team?.name}</h2>
            <DefaultForm
              fields={TeamFormFields}
              formtagclassname="team-form"
              to_email_id={props?.choose_team?.email}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
                  show={showRC}
                  onHide={() => setShowRC(false)}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="more-filters-modal team-form"
                  id="filter-modal"
                  // fullscreen={"xl-down"}
                  backdrop="static"
                >
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={() => closeModalRC()}
                  >
                    <i className="icon black-cancel" />
                  </button>
                  <Modal.Body>
                    <div className="modal-body-container">
                      <h2>Request a call back</h2>
                      <DefaultForm
                        fields={RCFormFields}
                        formtagclassname="team-form"
                        // to_email_id={}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
    </section>
  )
}

export default CommunityStatistics
